body,
html {
  margin: 0;
  padding: 0;
  font-family: "Poppins", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
  box-sizing: border-box;
  height: 100vh;
  position: relative;
}
.PhoneInput--focus input {
  border: none;
  outline: none;
}
.PhoneInput input {
  border: none;
  outline: none;
  font-size: 16;
  font-family: "Poppins", sans-serif;
}
.sr-only {
  display: none;
}
.table > tbody {
  border-color: gray black !important;
}

.table-bordered > :not(caption) > * > * {
  border-width: 0.85px 2px !important;
}
.table-bordered > :not(caption) > * > * {
  word-wrap: break-word;
}
.MuiNativeSelect-icon {
  top: calc(50% - 12px);
  color: rgba(0, 0, 0, 0.54);
  position: absolute;
  pointer-events: none;
}

.table > thead {
  vertical-align: bottom;
  /* position: sticky;
  top: 0; */
}

.table > :not(caption) > * > * {
  padding: 0.5rem 0.5rem;
  background-color: var(--bs-table-bg);
  border-bottom-width: 1px;
  box-shadow: inset 0 0 0 9999px var(--bs-table-accent-bg);
  font-size: 12px;
}
.table .expand-cell {
  cursor: pointer;
}
.row,
.btn {
  font-size: 12.5px !important;
}

.form-control {
  display: block;
  width: 100%;
  padding: 0.275rem 0.75rem !important;
  font-size: 0.75rem !important;
  font-weight: 400;
  line-height: 0.5;
  color: #212529;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  -webkit-appearance: none;
  appearance: none;
  border-radius: 0.6rem !important;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.expand-cell-header {
  border-bottom-color: currentColor !important;
  background-color: #63244e !important;
  color: #fff !important;
  border: 1px solid black;
  position: sticky !important;
  top: 0 !important;
  /* display: none !important; */
}
/* .row {
  position: sticky !important;
  bottom: 0 !important;
  background-color: #63244E !important;
  color: white;
} */

.btn-secondary {
  color: #fff !important;
  background-color: #63244e !important;
  border-color: #63244e !important;
}
.page-item.active .page-link {
  z-index: 3;
  color: #fff !important;
  background-color: #63244e !important;
  border-color: #63244e !important;
}
.page-link {
  position: relative;
  display: block;
  color: #63244e !important;
  text-decoration: none;
  background-color: #fff;
  border: 1px solid #dee2e6;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.makeStyles-container-37::-webkit-scrollbar {
  width: 8px;
}
.makeStyles-container-37::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0);
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0);
}
.makeStyles-container-37::-webkit-scrollbar-thumb {
  background-color: #2e9f92;
  border-radius: 1px;
}

.MuiNativeSelect-select.MuiNativeSelect-select {
  font-size: 14px !important;
}
.row-expand-slide-appear-done .row-expand-slide-enter-done {
  background-color: red !important;
  padding: 100px !important;
}

.tox.tox-silver-sink.tox-tinymce-aux {
  display: none;
}
